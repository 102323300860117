<template>
  <div class="mb-3 card transparent">
    <div>
      <v-tabs v-model="tab" class="rounded-t">
        <v-tab key="list">
          {{ $t("erp.lang_incomingGoods") }}
        </v-tab>
        <v-tab key="add">{{ $t('erp.lang_registerIncomingGoods') }}</v-tab>
        <v-tab key="preorder">{{ $t('erp.lang_orderedGoods') }}</v-tab>

        <v-tabs-items v-model="tab">
          <v-tab-item key="list">
            <v-card elevation="0">
              <v-card-title>

                <v-col cols="12" sm="6" md="4">
                  <BaseDateInput clearable class="rounded-0" hide-details v-model="startDateTime"
                                :label="$t('accounting.lang_dailyReportStart')"
                                :rules="[v => !!v]" dense outlined
                                type="date"/>
                </v-col>
                <v-col cols="12" sm="6" md="4">
                  <BaseDateInput clearable class="rounded-0" hide-details v-model="endDateTime"
                                :label="$t('accounting.lang_dailyReportEnd')"
                                :rules="[v => !!v]" dense outlined
                                type="date"/>
                </v-col>
                <v-col cols="12" sm="12" md="4">
                  <v-select hide-details :loading="storagesLoader" @change="$forceUpdate()" v-model="storage_id"
                            :items="this.storageSelect"
                            class="ma-0 pa-0 rounded-0" clearable
                            dense
                            item-text="name" item-value="id" :label="$t('erp.lang_selectStorage')"
                            outlined/>
                </v-col>
              </v-card-title>
              <v-card-text class="px-0">
                <Datatable :api-endpoint="ENDPOINTS.DATATABLES.ERP.GOODSRECEIPT"
                           :datatable-headers="datatableHeaders"
                           :excel-columns="excelColumns"
                           @deleteEntries="deleteEntries"
                           @deleteEntry="deleteEntry"
                           @displayEntry="displayEntry"
                           @editEntry="editEntry"
                           @downloadExcel="downloadExcel"
                           excel-file-name="GoodsReceipt"
                           ref="GoodsReceiveDatatable"
                           show-delete-buttons
                           show-display-buttons
                           show-edit-buttons
                           show-excel-buttons
                           :elevation="0"
                           :data="this.params"
                           v-show="showTable"
                >
                  <template v-slot:item.value="{item}">
                    {{ item.value | currency }}
                  </template>
                </Datatable>
              </v-card-text>
            </v-card>


          </v-tab-item>
          <v-tab-item key="add">
            <v-container fluid>
              <v-layout>
                <v-flex md6 ml-2 mr-2>
                  <v-text-field :data-layout="KEYBOARD.KEYSETS.NORMAL"
                                @focus="showTouchKeyboard"
                                autocomplete="off"
                                :label="$t('erp.lang_nameOfGoodsReceipt')"
                                outlined
                                required
                                v-model="GoodsReceiptName"
                  ></v-text-field>
                </v-flex>
                <v-flex md6 ml-2 mr-2>
                  <v-select :items="storageSelect"
                            item-text="name"
                            item-value="id"
                            :label="$t('erp.lang_targetStore')"
                            outlined
                            required
                            v-model="targetStorage"
                  ></v-select>
                </v-flex>
              </v-layout>

              <v-layout>
                <v-flex class="text-right">
                  <v-btn @click="showList" color="error" text>{{ $t('generic.lang_prev') }}</v-btn>
                  <v-btn :disabled="GoodsReceiptName.length < 4 || targetStorage < 1" @click="addData"
                         color="success">{{
                      $t('generic.lang_next')
                    }}
                  </v-btn>
                </v-flex>
              </v-layout>
            </v-container>
          </v-tab-item>

          <v-tab-item class="pa-0" key="preorder">
            <pre-order-goods-component/>
          </v-tab-item>
        </v-tabs-items>
      </v-tabs>
    </div>

    <div id="onScreenKeyboardDiv" v-click-outside="dismissOnScreenKeyboard">
      <vue-touch-keyboard :accept="hideTouchKeyboard" :cancel="hideTouchKeyboard"
                          :defaultKeySet="touchKeyboard.keySet"
                          :input="touchKeyboard.input" :layout="touchKeyboard.layout"
                          :options="touchKeyboard.options" class="internalWidth"
                          id="onScreenKeyboard" v-if="touchKeyboard.visible"/>
    </div>
  </div>
</template>

<style>
.dataTables_filter {
  display: none;
}
</style>

<script>
import {ENDPOINTS} from '@/config'
import {Events} from "@/plugins/events";
import Datatable from "../../../datatable/Datatable";
import mixin from "../../../../mixins/KeyboardMixIns";
import PreOrderGoodsComponent from "@/components/erp/storage/goodreceipt/preorder/PreOrderGoodsComponent";
import BaseDateInput from "@/components/common/BaseDateInput.vue";
import FileSaver from 'file-saver';

export default {
  components: {
    PreOrderGoodsComponent,
    Datatable,
    BaseDateInput
  },
  beforeDestroy() {
    localStorage.setItem("tab", this.tab + '')
  },
  mixins: [mixin],
  data() {
    return {
      startDateTime: "",
      endDateTime: "",
      storagesLoader: "",
      storage_id: "",
      ENDPOINTS,
      id: "",
      GoodsReceiptName: "",
      targetStorage: 0,
      storageSelect: [],
      search: "",
      loading: false,
      showTable: true,
      showCreate: false,
      tab: parseInt(localStorage.getItem("tab")) || 0,
      // --- Datatable ---
      datatableHeaders: [
        {
          text: 'ID',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true
        },
        {text: this.$t('generic.lang_name'), value: "name"},
        {text: this.$t('generic.lang_constant_timing'), value: "time", sort: 'desc'},
        {text: this.$t('erp.lang_targetStore'), value: "targetStock"},
        {text: this.$t('erp.lang_orderedItems'), value: "stacks"},
        {text: this.$t('erp.lang_totalQTY'), value: "qty"},
        {text: this.$t('erp.lang_PurchaseValue'), value: "value"},
        {text: this.$t('generic.lang_committed'), value: "transferCompleted"},
        {text: this.$t('generic.lang_committedAt'), value: "completed_At"},
      ],
      excelColumns: [
        {
          label: "ID",
          field: 'id',
        },
        {
          label: this.$t('generic.lang_constant_timing'),
          field: 'time',
        },
        {
          label: this.$t('erp.lang_targetStore'),
          field: 'targetStock',
        },
        {
          label: this.$t('erp.lang_orderedItems'),
          field: 'stacks',
        },
        {
          label: this.$t('erp.lang_totalQTY'),
          field: 'qty',
        },
        {
          label: this.$t('erp.lang_PurchaseValue'),
          field: 'value',
        },
        {
          label: this.$t('generic.lang_committed'),
          field: 'transferCompleted',
        },

      ],
    }
  },
  computed: {
    params() {
      return {
        storage_id: this.storage_id,
        start_date: isNaN(new Date(this.startDateTime).getTime() / 1000) ? '' : new Date(this.startDateTime).getTime() / 1000,
        end_date: isNaN(new Date(this.endDateTime).getTime() / 1000) ? '' : new Date(this.endDateTime).getTime() / 1000
      }
    },
  },
  watch: {
    tab: function () {
      if (this.tab === 1) {
        this.showTable = true;
      }
    },
    startDateTime(val) {
      if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() > new Date(val).getTime()) {
        this.reloadDataTable();
        //this.loadData();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_before') + " : " + this.endDateTime
        });
        this.startDateTime = "";
      }
    },
    endDateTime(val) {
      if (this.startDateTime != "" && this.startDateTime != null && new Date(this.startDateTime).getTime() < new Date(val).getTime()) {
        this.reloadDataTable();
      } else if (this.endDateTime != "" && this.endDateTime != null && new Date(this.endDateTime).getTime() <= new Date(val).getTime()) {
        Events.$emit("showSnackbar", {
          color: "error",
          message: this.$t('generic.lang_select_date_after') + " : " + this.startDateTime
        });
        this.endDateTime = "";
      }
    },
    storage_id() {
      this.reloadDataTable();
    }
  },

  methods: {
    reloadDataTable() {
      const self = this;
      let timer = setTimeout(function () {
        self.$refs.GoodsReceiveDatatable.getDataFromApi();
        clearTimeout(timer);
      }, 1000)
    },
    deleteData(ids) {
      this.$swal({
        title: this.$t('erp.lang_doYouReallyWantToClearItems'),
        text: this.$t('erp.lang_doYouReallyWantToClearItems'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.CLEAR, {
            storageTransferIDs: ids
          }).then((res) => {
            if (res.data.status === 'SUCCESS') {
              Events.$emit("showSnackbar", {
                message: this.$t('erp.lang_itemSuccessfulDeleted'),
                color: "success"
              });

              this.$refs.GoodsReceiveDatatable.getDataFromApi();
              this.$refs.GoodsReceiveDatatable.resetSelectedRows();
            } else if (res.data.status === 'ERROR') {
              Events.$emit("showSnackbar", {
                message: res.data.msg,
                color: "error"
              });

              this.$refs.GoodsIssueDatatable.getDataFromApi();
              this.$refs.GoodsIssueDatatable.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch((err) => {
            Events.$emit("showSnackbar", {
              message: err.message,
              color: "error"
            });
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading
      });
    },
    deleteEntries(ids) {
      this.deleteData(ids)
    },
    deleteEntry(entry) {
      if (parseInt(entry.protected) == 1) {
        this.$swal({
          title: this.$t('erp.lang_youCannotDeleteThisItem'),
          text: this.$t('erp.lang_youCannotDeleteThisItem'),
          icon: "warning",
        })
      } else
        this.deleteData([entry.id])
    },
    loadStorages: function () {
      let self = this;
      this.storagesLoader = true;
      self.axios.get(ENDPOINTS.ERP.STORAGES.GET)
          .then((res) => {
            if (res.status === 200) {
              res.data.storageInformation.forEach((row) => {
                this.storageSelect.push({name: row[1], id: parseInt(row[0])});
              });
            } else {
              Events.$emit("showSnackbar", {
                message: self.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          }).catch(() => {
        Events.$emit("showSnackbar", {
          message: self.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      }).finally(() => (this.storagesLoader = false));
    },
    editEntry(entry) {
      this.$router.replace('/erp/storage/GoodsReceipt/edit/' + entry.id);
    },
    displayEntry(entry) {
      this.$router.replace('/erp/storage/GoodsReceipt/details/' + entry.id);
    },
    downloadExcel(entry) {
      this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.DOWNLOAD_EXCEL, { uuid: entry.id },
        {
          responseType: 'arraybuffer',
        }).then((res) => {
          //DOWNLOAD excel
          FileSaver.saveAs(new Blob([res.data], {
            type: "application/application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
          }), "Good receipt.xlsx");
        }).catch((err) => {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred') + '\n' + err.message,
            color: "error"
          });
        }).finally(() => {
        })
    },
    addData: function () {
      let self = this;
      this.axios.post(ENDPOINTS.ERP.GOODSRECEIPT.CREATE, {
        GoodsReceiptName: self.GoodsReceiptName,
        targetStorage: self.targetStorage
      }).then((res) => {
        if (res.data.status === 'SUCCESS') {
          Events.$emit("showSnackbar", {
            message: self.$t('generic.lang_goodsReceiptEntryStarted'),
            color: "success"
          });
          self.id = res.data.UUID;
          //NAVIGATE TO GOODSRECEIPT /DISPLAY PAGE
          this.$router.replace({name: 'erp.storage.GoodsReceiptDetails', params: {id: this.id}})

        } else if (res.data.status === 'ERROR') {
          Events.$emit("showSnackbar", {
            message: res.data.msg,
            color: "error"
          });
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }
      }).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('generic.lang_errorOccurred'),
          color: "error"
        });
      });
    },
    createNew: function () {
      this.resetData();
      this.showCreate = true;
      this.tab = 1;
      this.showTable = false;
    },


    resetData: function () {
      this.showCreate = false;
      this.showTable = true;
      this.id = null;
      this.GoodsReceiptName = "";
      this.targetStorage = null;
    },
    showList: function () {
      this.resetData();
      this.tab = 0;
    },

  },
  mounted() {
    this.loadStorages();
  }
}
</script>
