<template>
  <div class="main-card mb-3 card pa-0 ma-0 shadow-none transparent">
    <div class="card-body pa-0">
      <div :class="[this.$vuetify.theme.dark? 'dark-bg' : '']" class="card-title card-header-tab card-header">

        <div class="card-header-title font-size-lg text-capitalize font-weight-normal">
          {{ $t('erp.lang_viewOrders') }}
        </div>
        <div class="btn-actions-pane-right actions-icon-btn pt-6">
          <v-autocomplete :items="this.orders" @change="display($event)" autofocus class="ma-0 pa-0" clearable dense
                          flat
                          item-text="id" item-value="uuid" :label="$t('erp.lang_ordersScanner')"
                          outlined/>
        </div>

      </div>
      <div class="card-header-title">
        <v-select hide-details :loading="suppliersLoader" @change="$forceUpdate()" v-model="supplier_id"
                  :items="this.suppliers"
                  class="ma-0 pa-0 rounded-0 transparent" clearable
                  dense
                  flat
                  item-text="supplier_name" item-value="id" :label="$t('erp.lang_selectSupplier')"
                  outlined/>
      </div>


      <v-card-text class="pa-0 ma-0">
        <Datatable
            :api-endpoint="ENDPOINTS.DATATABLES.ERP.WAREORDERS"
            :custom-buttons="custombuttons"
            :data="{
              status: 2,
            supplier_id: supplier_id
            }"
            :datatable-headers="datatableHeaders"
            :elevation="0"
            :excel-columns="excelColumns"
            :permissionDelete="true"
            :permissionEdit="true"
            @deleteEntries="deleteEntries"
            @deleteEntry="deleteEntry"
            @displayEntry="displayEntry"
            @exportExcel="exportExcel"
            @exportPDF="exportPDF"
            class="ma-0"
            enable-multi-sort
            excel-file-name="orders"
            ref="orders"
            show-display-buttons
            show-select-checkbox
        >
          <template v-slot:item.totalNetBuyPrice="{item}">
            {{item.totalNetBuyPrice|currency}}
          </template>
          <template v-slot:item.status="{item , value}">
            {{ value }}
            <v-chip v-if="value === '0' && (new Date().getTime() >= new Date(item.expectedOn.split(' ')[0]).getTime())"
                    color="primary">
              {{$t('generic.lang_waiting')}}
            </v-chip>
            <v-chip v-if="value === '0' && (new Date().getTime() <= new Date(item.expectedOn.split(' ')[0]).getTime())"
                    color="error">
              {{$t('generic.lang_waiting')}}
            </v-chip>
            <v-chip v-else-if="value === '1'" color="warning">
              {{$t('erp.lang_partialDelivery')}}
            </v-chip>
            <v-chip v-else-if="value === '2'" color="success">
              {{$t('generic.completed')}}
            </v-chip>
          </template>
        </Datatable>
      </v-card-text>

      <div>
        <excel-exporter ref="excelExporter" v-show="false"/>
      </div>

    </div>

    <!-- WARE ORDER PDF DIALOG -->
    <v-dialog :scrollable="false" overlay-opacity="0.5" persistent v-model="displayOrderWareDialog" width="800">
      <v-card>
        <div class="card-header">
          <v-btn @click="download" color="warning" icon>
            <v-icon>cloud_download</v-icon>
          </v-btn>

          <v-btn @click="print" color="primary" icon>
            <v-icon>print</v-icon>
          </v-btn>
          <v-spacer/>
          <v-btn @click="closeIframe" color="error" icon>
            <v-icon>close</v-icon>
          </v-btn>
        </div>
        <v-card-text style="padding: 0; height: 600px;">
          <iframe :src="iframePDFContent" download="pdfFile" height="600" id="orderPDF" name="pdf_file"
                  v-if="iframePDFContent.length > 0" width="800"/>
        </v-card-text>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import {ENDPOINTS} from "../../../../../config";
import Datatable from "../../../../datatable/Datatable";
import {Events} from "../../../../../plugins/events";
import {mapState} from "vuex";
import FileSaver from 'file-saver';
import ExcelExporter from "@/components/erp/storage/orderware/ExcelExporter";

export default {
  name: "PreOrderGoodsComponent",
  components: {
    ExcelExporter,
    Datatable
  },
  data: () => {
    return {
      ENDPOINTS,
      selectedID: null,
      displayOrderWareDialog: false,
      iframePDFContent: "",
      suppliersLoader: false,
      supplier_id: null,
      suppliers: [],
      dialog: false,
      order: null,
      pdf64: null,
      orders: [],
      custombuttons: [
        {
          text: "picture_as_pdf",
          color: "warning",
          eventRow: "exportPDF",
          layout: {
            outlined: false,
            flat: false,
            text: false,
            icon: true
          }
        },
        {
          text: "pe-7s-file",
          color: "success",
          eventRow: "exportExcel",
          layout: {
            outlined: false,
            flat: false,
            text: false,
            icon: true
          }
        }
      ]
    }
  },

  watch: {
    supplier_id(id) {
      this.$forceUpdate();
      id & this.reloadDataTable();
    }
  },
  methods: {
    reloadDataTable() {
      const self = this;
      let timer = setTimeout(function () {
        self.$refs.orders.getDataFromApi();
        clearTimeout(timer);
      }, 1000)
    },
    display(uuid) {

      if (uuid !== undefined && uuid !== null && uuid.length > 0)
        this.$router.push('/erp/storage/orders/itemscontrol/' + uuid);
    },
    loadOrders() {
      this.axios.post(ENDPOINTS.DATATABLES.ERP.WAREORDERS + '?status=2').then((res) => {
        this.orders = res.data.aaData.map((elt) => {
          return {
            uuid: elt[0],
            id: elt[1],
            name: elt[2]
          }
        })
      }).then((err) => {

      }).finally(() => {

      })
    },
    print() {
      if (document.getElementById("orderPDF")) {
        var frm = document.getElementById("orderPDF").contentWindow;
        frm.focus();// focus on contentWindow is needed on some ie versions
        frm.print();
      }
      return false;
    },
    closeIframe() {
      this.displayOrderWareDialog = false;
      this.order = null;
      this.pdf64 = false;
      this.iframePDFContent = "";
    },
    download() {
      FileSaver.saveAs(new Blob([this.pdf64], {
        type: "application/pdf"
      }), this.order.order.reference + '-' + this.order.supplier.supplier_name + '-' + new Date().toLocaleDateString() + ".pdf");
    },
    displayEntry(entry) {
      this.$router.push('/erp/storage/orders/itemscontrol/' + entry.id);
    },
    deleteData(idsToDelete = []) {
      let self = this;
      this.$swal({
        title: this.$t('erp.lang_deleteOrder'),
        text: this.$t('erp.lang_deleteOrder'),
        icon: "warning",
        cancelButtonText: this.$t('generic.lang_cancel'),
        confirmButtonText: this.$t('generic.lang_delete'),
        showCancelButton: true,
        showLoaderOnConfirm: true,
        preConfirm: () => {
          if (!idsToDelete || !idsToDelete.length || idsToDelete.length === 0) {
            idsToDelete = [];
          }
          this.axios.post("delete/erp/orders/", {
            orderIDs: idsToDelete,
          }).then((res) => {
            if (res.status === 200) {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_success'),
                color: "success"
              });
              self.$refs.orders.getDataFromApi();
              self.$refs.orders.resetSelectedRows();
            } else {
              Events.$emit("showSnackbar", {
                message: this.$t('generic.lang_errorOccurred'),
                color: "error"
              });
            }
          });
        },
        allowOutsideClick: () => !this.$swal.isLoading,
      });
    },
    deleteEntry(entry) {

      this.deleteData([entry.id]);
    },
    deleteEntries(entries) {
      this.deleteData(entries);
    },
    exportPDF(entry) {
      this.dialog = true;
      let promises = Array();
      promises.push(this.axios.post(ENDPOINTS.ERP.ORDERWARE.PRINTA4, {
        orderID: entry.id
      }));

      promises.push(this.axios.post(ENDPOINTS.ERP.ORDERWARE.DETAILS, {
        orderUUID: entry.id
      }));

      Promise.all(promises).then(this.axios.spread((...res) => {

        if (res[1].data.STATUS === "SUCCESS") {
          this.order = res[1].data;
        } else {
          Events.$emit("showSnackbar", {
            message: this.$t('generic.lang_errorOccurred'),
            color: "error"
          });
        }

        if (res[0].data.status === 'SUCCESS') {
          this.axios.post(this.$store.getters['api/auth/pdfServer'] + '/template', {
                "header": {
                  "html": res[0].data.header,
                  "payload": {}
                },
                "footer": {
                  "html": res[0].data.footer,
                  "payload": {}
                },
                "body": {
                  html: res[0].data.template,
                  payload: res[0].data.values,
                  "margin": {
                    "top": "120px",
                    "bottom": "100px"
                  }
                },
              },
              {
                responseType: 'arraybuffer',
                headers: {
                  'POSID': this.api.auth.posID,
                  'ACCESSSTRING': this.api.auth.accessString,
                  'APITOKEN': this.api.auth.apiWebToken,
                }
              }
          ).then((pdf) => {
            //store pdf to use download it later
            this.pdf64 = [pdf.data];

            //GENERATE PDF CONTENT FOR iFRAME
            const blobContent = new Blob([pdf.data], {type: "application/pdf"});
            this.iframePDFContent = URL.createObjectURL(blobContent) + "#toolbar=0&navpanes=0&scrollbar=0";
            this.displayOrderWareDialog = true;

            //DOWNLOAD INVOICE AS PDF
            FileSaver.saveAs(new Blob([pdf.data], {
              type: "application/pdf"
            }), this.order.order.reference + '-' + this.order.supplier.supplier_name + '-' + new Date().toLocaleDateString() + ".pdf");


          }).catch(() => {

          });
        } else {

          Events.$emit("showSnackbar", {
            message: this.$t('erp.lang_orderPdfCouldNotBeDownloaded'),
            color: "error"
          });
        }


      })).catch(() => {
        Events.$emit("showSnackbar", {
          message: this.$t('erp.lang_orderPdfCouldNotBeDownloaded'),
          color: "error"
        });
      }).finally(() => {
        this.dialog = false;
      });


    },
    exportExcel(entry) {
      this.$refs.excelExporter.export(entry.id);
    },
    loadSuppliers() {
      this.suppliersLoader = true;
      this.axios.post(ENDPOINTS.ERP.SUPPLIERS.GETALL).then((res) => {
        this.suppliers = [...res.data.suppliers];
      }).catch(() => {

      }).finally(() => (this.suppliersLoader = false))
    }
  },
  mounted() {
    this.loadOrders();
    this.loadSuppliers();
  },
  computed: {
    ...mapState([
      'pos',
      'api'
    ]),
    datatableHeaders: function () {
      return [
        {
          text: 'id',
          align: 'left',
          value: 'id',
          width: 80,
          hide: true,
        },
        {text: this.$t('erp.lang_orderID'), value: 'reference', sortable: true, sort: 'desc'},
        {text: this.$t('generic.lang_name'), value: 'name', sortable: false},
        {text: this.$t('erp.lang_nav_supplier'), value: 'supplier'},
        {text: this.$t('erp.lang_toStorage'), value: 'storage', sortable: false},
        {text: this.$t('generic.lang_date'), value: 'date', sortable: false},
        {text: this.$t('erp.lang_orderedUnits'), value: 'totalItems'},
        {text: this.$t('erp.lang_orderedItems'), value: 'totalPositions'},
        {text: this.$t('erp.lang_ek_net_short'), value: 'totalNetBuyPrice'},
        {text: this.$t('erp.lang_orderImported'), value: 'restockCompletedTimestamp'},
        {text: this.$t('generic.lang_status'), value: 'status'},
        {text: "", value: 'expectedOn', hide: true},
      ]
    },
    excelColumns: function () {
      return [
        {
          label: 'id',
          field: 'id',
          hide: true,
        },
        {label: this.$t('generic.lang_name'), field: 'name'},
        {label: this.$t('erp.lang_nav_supplier'), field: 'supplier'},
        {label: this.$t('erp.lang_toStorage'), field: 'storage'},
        {label: this.$t('generic.lang_date'), field: 'date'},
        {label: this.$t('erp.lang_orderedUnits'), field: 'totalItems'},
        {label: this.$t('erp.lang_orderedItems'), field: 'totalPositions'},
        {label: this.$t('erp.lang_ek_net_short'), field: 'totalNetBuyPrice'},
      ]
    }
  }
}
</script>

<style scoped>

</style>
