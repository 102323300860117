<template>
    <vue-excel-xlsx
            :columns="Columns"
            :data="positions"
            :filename="excelFileName"
            :sheetname="'Sheet1'"
    >
        <button class="dropdown-item" id="export" tabindex="0"
                type="button">
            <span></span></button>

    </vue-excel-xlsx>
</template>

<script>
    import {ENDPOINTS} from "../../../../config";

    export default {
        name: "ExcelExporter",
        methods: {
            priceFormat(value) {
                return this.$options.filters.currency(value);
            },
            export(orderID) {
                this.positions = [];
                this.counter = 1;
                this.axios.post(ENDPOINTS.ERP.ORDERWARE.DETAILS, {
                    orderUUID: orderID
                }).then((res) => {
                    this.positions = res.data.positions;
                    this.excelFileName = res.data.order.restockOrderName;
                }).catch((err) => {

                }).finally(() => {
                    document.getElementById("export").click();
                })
            },
            counterFormater(value){
                return this.counter++;
            }
        },
        data: () => {
            return {
                positions: [],
                excelFileName: "order"
            }
        },
        computed: {
            Columns: function () {
                return [
                    {
                        label: this.$t('generic.lang_position'),
                        field: "",
                        dataFormat: this.counterFormater
                    },
                    {
                        label: this.$t('erp.lang_eanNr') + ".",
                        field: "ean"
                    },
                    {
                        label: this.$t('erp.lang_supplierItemNr'),
                        field: "number"
                    },
                    {
                        label: this.$t('erp.lang_posItemName'),
                        field: "wareName"
                    },
                    {
                        label: this.$t('generic.lang_packaging'),
                        field: "packaging"
                    },
                    {
                        label: this.$t('generic.lang_cashier_price'),
                        field: "price",
                        dataFormat: this.priceFormat
                    },
                    {
                        label: this.$t('generic.lang_total'),
                        field: "total",
                        dataFormat: this.priceFormat
                    }
                ]
            }
        }
    }
</script>

<style scoped>

</style>